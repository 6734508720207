.container{
    height: 100vh;
    width: 100%;
    background-color: #fff;
    overflow-y: scroll;
    padding: 20px;
    box-sizing: border-box;
}
.pageTitle{
    font-size: 20px;
    margin: 0;
    color: #000;
    font-weight: 600;
}

/******** Banance box styles **********/
.balanceBoxes{
    width: 100%;
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}
.BalanceBox{
    width: 100%;
    /* border: 0.5px solid #c4c4c4; */
    box-shadow: 0px 4px 10px rgba(0,0,0,0.07);
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
}
.BalanceBox:hover .BalanceBox_top div{
    border-color: var(--purple);
    color: var(--purple);
}
.BalanceBox_top{
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}
.BalanceBox_top div{
    height: 30px;
    width: 30px;
    border-radius: 30px;
    border: 0.5px solid #8b8b8b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6d6d6d;
}
.BalanceBox_main{
    margin-top: 30px;
    align-items: center;
}
.BalanceBox_main h2{
    margin: 0;
    font-weight: 600;
    font-size: 22px;
    color: #2c2c2c;
}
.view_balance{
    width: 20px;
    display: grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
@media (max-width: 1000px) {
    .balanceBoxes{
        grid-template-columns: repeat(2, 1fr);
    }
}

/********** Transactions stylings ************/
.transactions{
    width: 100%;
    margin-top: 40px;
}
.transactions_top{
    display: grid;
    grid-template-columns: 1fr 150px;
    align-items: center;
}
.transactions_top h2{
    font-size: 20px;
    margin: 0;
    font-weight: 500;
}
.transactions_top button{
    height: 40px;
    width: 100%;
    background-color: var(--purple);
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 7px;
}
.transactions_container{
    width: 100%;
    border-radius: 20px;
    border: 0.5px solid #c4c4c4;
    margin-top: 25px;
    overflow: hidden;
}
.transactions_header{
    height: 50px;
    width: 100%;
    border-bottom: 0.5px solid #c4c4c4;
    padding: 0 20px;
    box-sizing: border-box;
    display: grid;
    align-items: center;
    gap: 20px;
}
.transactions_header div{
    font-size: 14px;
    opacity: 0.7;
}
.transactions_header_date{
    display: flex;
    justify-content: flex-end;
}
.transactions_header_amount, .transactions_header_status{
    display: flex;
}
.empty_transactions{
    height: 400px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.empty_transactions img{
    height: 150px;
}
.empty_transactions_title{
    font-size: 16px;
    margin: 0;
    margin-top: 15px;
    font-weight: 600;
}
.empty_transactions_details{
    font-size: 14px;
    margin: 0;
    margin-top: 7px;
    opacity: 0.6;
}
@media (max-width: 600px) {
    .transactions_header{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .transactions_header_sn{
        display: none;
    }
}