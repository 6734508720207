.container{
    width: 100vw;
    background-color: #f5f5f5;
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 70px;
}
.container h1{
    color: #000;
    margin: 0;
    margin-bottom: 15px;
    text-align: center;
}
.container h1 span{
    color: var(--purple);
}
.intro{
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    opacity: 0.7;
    text-align: center;
}
.mainContainer{
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
}
.Item{
    max-width: 350px;
    border: 0.5px solid var(--purple);
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;
}
.middle{
    transform: scale(1.1);
}
.top{
    width: 100%;
    background-color: var(--purple);
    padding: 20px;
    box-sizing: border-box;
    color: #fff;
    text-align: center;
}
.icon{
    height: 60px;
    width: 60px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 30px;
    background-color: #fff;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.top h3{
    margin: 0;
    margin-top: 15px;
}
.top p{
    margin: 0;
    margin-top: 7px;
    font-size: 14px;
    opacity: 0.9;
    font-weight: 500;
}
.detail{
    padding: 15px 20px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    gap: 10px;
    font-size: 15px;
    border-bottom: 0.5px solid #aeaeae;
}
.detail p{
    margin: 0;
    text-align: right;
}
.Item .bottom{
    padding: 20px;
    box-sizing: border-box;
}
.Item button{
    height: 45px;
    width: 100%;
    background-color: var(--purple);
    color: #fff;
    border: none;
    border-radius: 7px;
}

@media (max-width: 500px) {
    .container{
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
    }
    .container h1{
        font-size: 23px;
    }
    .intro{
        margin: 0;
        font-size: 14px;
        line-height: 150%;
    }
    .mainContainer{
        flex-direction: column;
        -ms-grid-row-span: 20px;
    }
    .Item{
        width: 100%;
    }
    .middle{
        transform: scale(1);
    }
}